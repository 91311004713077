@font-face {
  font-family: 'TT-Common-R';
  font-display: swap;
  src: url(../public/assets/fonts/AvenirRegular.woff2) format('woff2');
}

@font-face {
  font-family: 'TT-Common-M';
  font-display: swap;
  src: url(../public/assets/fonts/AvenirBook.woff2) format('woff2');
}

@font-face {
  font-family: 'TT-Common-D';
  font-display: swap;
  src: url(../public/assets/fonts/AvenirBlack.woff2) format('woff2');
}

@font-face {
  font-family: 'TT-Common-B';
  font-display: swap;
  src: url(../public/assets/fonts/AvenirHeavy.woff2) format('woff2');
}

:root {
  --WhiteVM: #fff;
  --BlackVM: #000;
  --LightBlackVM: #060A18;
  --DarkVM: #171B27;
  --LightVM: #FAFAFB;
  --PrimaryVM: #F19938;
  --PrimaryLightVM: rgba(241, 153, 56, .6);
  --BlueVM: #06143A;
}
#root {
  height: 100%;
}


html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}


body {
  margin: 0;
  padding: 0;
  /* font-family: "Inter", sans-serif; */
  font-family: 'TT-Common-R', Arial, sans-serif;
  background-color: var(--DarkVM);
  overflow-x: hidden;
  color: var(--LightVM);
}

body.lightModeOn{
  background: var(--WhiteVM);
  color: var(--BlackVM);
}
.toogle-btn svg path{
    fill: var(--WhiteVM);
}
.lightModeOn .toogle-btn svg path{
  fill: var(--PrimaryVM);
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}


.fs-12 {
  font-size: 12px !important;
}

.fs-48 {
  font-size: 48px !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.primary-text {
  color: var(--PrimaryVM) !important;
}
.primaryBg {
  background: var(--PrimaryVM);
}

.blackBg{
  background: var(--BlackVM);
}
.lightModeOn .blackBg{
  background: var(--LightVM);
}

.white-text{
  color: white;
}
.lightModeOn .white-text{
  color: var(--LightBlackVM);
}

.no-wrap,.eco-pills .nav-tabs{
  flex-wrap: nowrap !important;
}
.link-text {
  color: #4C90DF;
  text-decoration: underline;
}

.text-link {
  color: #4C90DF;
}
.d-able{
  pointer-events: none;
}
.op-light{
  display: none;
}
.lightModeOn .op-light{
  display: inline-block;
}

.lightModeOn .op-dark{
  display: none;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-18 {
  font-size: 18px !important;
}


p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

p span {
  background: linear-gradient(-5deg, #FFD400, #F27A01);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}
.nodesale p span{
  color: #F19938 ;
  background: transparent;
  -webkit-text-fill-color: #F19938;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'TT-Common-R';
  line-height: 1;
}

h2 {
  font-size: 50px;
  line-height: 56px;
}

h2 span {
  color: #F19938;
}
h4 {
  font-size: 30px;
  line-height: 32px;
}
h5 {
  font-size: 24px;
  line-height: 29px;
}

/* Header  */

.navbar {
  /* height: 85px; */
  position: fixed;
  width: 100%;
  z-index: 111;
  top: 0;
  /* background: linear-gradient(122.96deg, #FEF8F4 30.33%, #FFFFFF 119.91%); */
}

.bg-pink {
  /* background: #498FF8; */
  backdrop-filter: blur(10px);
}


.navbar .menu-link {
  /* font-family: 'TT-Common-R'; */
  font-size: 15px;
  color: var(--WhiteVM);
  margin: 0 5px;
  position: relative;
  transition: all .2s ease-in-out;
  text-align: center;
}

.navbar .nav-link span{
  color: var(--PrimaryVM);
  position: absolute;
  bottom: 0px;
  right: 10px;
  font-size: 10px;
}

.lightModeOn .navbar .menu-link {
  color: var(--LightBlackVM);
}


.navbar .menu-link:hover,.navbar .menu-link.active {
  color: #F19938 !important;
  transition: all .2s ease-in-out;
}

/* .navbar .nav-link img {
  height: 22px;
  transition: all .2s ease-in-out;

}

.navbar .nav-link:hover img {
  transform: translate(0px, -10px);
  transition: all .2s ease-in-out;
} */


.navbar-nav {
  position: relative;
}

/* .navbar-nav::after {
  content: "";
  background: #2AE8DC;
  height: 2px;
  width: 100%;
  bottom: -5px;
  left: 0;
  position: absolute;
} */

.navbar-collapse.collapsing {
  transition: none !important;
}

.lightModeOn .bg-pink{
  background: #ffffff94;
}

/* Banner */

.top-banner {
  background: #FDBA0D;
  padding: 12px 0;
  text-align: center;
  font-size: 16px;
  width: 100%;
  /* line-height: 16px; */
  color: #13161F;
}

.top-banner a {
  color: #13161F;
  text-decoration: underline;
  font-weight: bold;
}

.bg-grad {
  padding: 120px 0px 0px 0px;
}

.banner {
  padding: 30px 0px 60px 0px;
}

.banner h1 {
  font-size: 46px;
  line-height: 64px;
  display: inline-block;
}
/* .nodesale .banner h1{
  font-size: 58px;
  line-height: 66px;
} */

.banner h1 span {
  color: #F19938;
}

.banner p {
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
}
.banner-card{
  background: var(--BlackVM);
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 20px;
  padding: 25px;
}
.home-banner{
  position: relative;
}
.b1-img{
  position: absolute;
  left: 0;
  top: 0;
  max-width: 50%;
}
.b2-img{
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 30%;
}

.test {
  border: 1px solid rgba(255, 255, 255, .4);
  font-size: 20px;
  border-radius: 40px;
  padding: 12px 80px;
  display: inline-block;
  margin-bottom: 100px;
}

/* .sp-img img {
  height: 0px;
} */

.primary-btn {
  color: #fff;
  padding: 12px 30px;
  border-radius: 12px;
  font-size: 16px;
  /* font-weight: 700; */
  font-family: 'TT-Common-M';
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #F19938;
  border: 1px solid #F19938;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /* transform: translate(0px, 0px); */
  transition: all .3s ease-in-out;
}


.primary-btn:hover {
  background: transparent;
  /* transform: translate(0px, -5px); */
  color: #F19938;
  transition: all .3s ease-in-out;
}

.primary-btn-outline{
  color: #fff;
  padding: 12px 30px;
  border-radius: 12px;
  font-size: 16px;
  /* font-weight: 700; */
  font-family: 'TT-Common-M';
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  gap: 20px;
  border: 1px solid #F19938;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /* transform: translate(0px, 0px); */
  transition: all .3s ease-in-out;
}
.primary-btn-outline:hover {
  background: transparent;
  /* transform: translate(0px, -5px); */
  color: #F19938;
  transition: all .3s ease-in-out;
}
.primary-btn-outline svg path{
  transition: all .3s ease-in-out;
}
.primary-btn-outline:hover svg path{
  fill: var(--PrimaryVM);
  transition: all .3s ease-in-out;

}

nav .primary-btn {
  padding: 12px 40px;
}
nav .primary-btn:hover {
  transform: translate(0px, 0px);
}

.secondary-btn {
  color: #F19938;
  padding: 10px 30px;
  border-radius: 12px;
  font-size: 16px;
  /* font-weight: 700; */
  font-family: 'TT-Common-M';
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid #F19938;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translate(0px, 0px);
  transition: all .3s ease-in-out;
}


.secondary-btn:hover {
  background: #F19938;
  transform: translate(0px, -5px);
  color: #fff;
  transition: all .3s ease-in-out;
}

.learn-btn {
  color: var(--WhiteVM);
  padding: 10px 30px;
  border-radius: 12px;
  font-size: 16px;
  /* font-weight: 700; */
  font-family: 'TT-Common-M';
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: transparent;
  border: 1px solid transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translate(0px, 0px);
  transition: all .3s ease-in-out;
}
.lightModeOn .learn-btn{
  color: var(--BlackVM);
}

.learn-btn:hover {
  background: transparent;
  transform: translate(0px, 0px);
  color: var(--PrimaryVM);
  transition: all .3s ease-in-out;
}

.learn-btn-1 {
  color: var(--WhiteVM);
  padding: 10px 30px;
  border-radius: 12px;
  font-size: 14px;
  /* font-weight: 700; */
  font-family: 'TT-Common-M';
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border: 2px solid var(--PrimaryVM);
  background: transparent;
  background-position: center;
  color: var(--PrimaryVM);
  text-transform: uppercase;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translate(0px, 0px);
  transition: all .3s ease-in-out;
}
.lightModeOn .learn-btn-1{
  color: var(--BlackVM);
}

.learn-btn-1:hover {
  background: transparent;
  transform: translate(0px, 0px);
  color: var(--PrimaryVM);
  transition: all .3s ease-in-out;
}

.learn-btn svg{
  transform: translate(0px, 0px);
  transition: all .3s ease-in-out;
}
.learn-btn:hover svg{
  transform: translate(5px, 0px);
  transition: all .3s ease-in-out;
}
.lightModeOn .learn-btn svg path{
  fill: var(--BlackVM);
}
.learn-btn svg path{
  transition: all .3s ease-in-out;
}
.learn-btn:hover svg path{
  fill: var(--PrimaryVM);
  transition: all .3s ease-in-out;
}

.lightModeOn .navbar-toggler svg path{
        fill: var(--BlackVM);
}
.z-1 {
  z-index: 1;
}


.language-dropdown {
  color: #fff;
  padding: 10px 25px;
  border-radius: 12px;
  font-size: 16px;
  /* font-weight: 700; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #F19938;
  border: 1px solid #F19938;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all .3s ease-in-out;
}

.dropdown-menu{
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding:  0;
  border: 0px solid transparent;
  min-width: 8rem;
}
.bg-pink .dropdown-menu{
  background: rgba(255, 255, 255, 0) !important;
  backdrop-filter: blur(10px) !important;
}

.dropdown-menu li {
  padding: 10px 20px;
}
.dropdown-menu li a{
  position: relative;
  color: white;
  font-size: 14px;
}
.lightModeOn .dropdown-menu li a{
  color: black;
}
.dropdown-menu li:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.dropdown-menu li:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding-bottom: 15px;
}

.dropdown-menu li:hover, .dropdown-menu li.active {
  background: rgba(23, 27, 39, .5);
  transition: all .2s ease-in-out;
}

.lightModeOn .dropdown-menu li:hover, .lightModeOn .dropdown-menu li.active {
  background: #EDEEF1;
  transition: all .2s ease-in-out;
}

.dropdown-menu li a span{
  color: white;
  position: absolute;
  bottom: -10px;
  right: 0px;
  font-size: 10px;
  transition: all .2s ease-in-out;
}
.dropdown-menu li:hover a, .dropdown-menu li.active a, 
.dropdown-menu li.active a span,.dropdown-menu li:hover a span {
  color: white;
  transition: all .2s ease-in-out;
}

.lightModeOn .dropdown-menu li a span{
  color: black;
}
.lightModeOn .dropdown-menu li:hover a,.lightModeOn .dropdown-menu li.active a, 
.lightModeOn .dropdown-menu li.active a span,.lightModeOn .dropdown-menu li:hover a span {
  color: black;
  transition: all .2s ease-in-out;
}

footer .f-card li a{
  position: relative;
}
footer .f-card li a span{
  color: var(--PrimaryVM);
  position: absolute;
  bottom: -10px;
  right: 0px;
  font-size: 10px;
}

@media (max-width: 992px) {
  .language-dropdown {
    padding: 8px 20px;
    border-radius: 12px;
    font-size: 14px;
  }
  .dropdown-menu li {
    padding: 7px 20px;
  }
  .dropdown-menu li a {
    font-size: 14px;
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 950px;
  }

  .navbar-toggler {
    color: #2B1D11;
    /* font-size: 36px; */
    border: none;
    background: none;
  }

  .navbar {
    /* position: fixed; */
    z-index: 1111;
    left: 0;
    top: 0px;
    height: auto;
    width: 100%;
  }

  .navbar-collapse {
    position: fixed;
    left: -400px;
    opacity: 0;
    transition: all .3s linear;
    display: block !important;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    opacity: 1;
    width: 92%;
    left: 0;
    border-right: 1px solid var(--PrimaryVM);
    height: 100vh;
    background: var(--BlackVM);
    backdrop-filter: blur(10px);
    z-index: 111;
    left: 0px;
    transition: all .3s linear;
    display: block !important;
  }

  .lightModeOn .navbar-collapse {
    background: var(--LightVM);
    backdrop-filter: blur(10px);
  }


  .cross-btn {
    font-size: 24px;
    color: var(--WhiteVM) !important;
  }
.lightModeOn  .cross-btn {
  color: var(--BlackVM) !important;
}
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .navbar .nav-link span {
    right: 0px;
}
  .navbar-nav {
    align-items: flex-start !important;
  }
  .navbar .menu-link {
      font-size: 20px;
  }

  .navbar-brand img {
    height: 36px;
  }
  .toogle-btn svg{
    height: 24px;
  }

  /* .navbar-nav {
    gap: 20px;
    flex-direction: row;
    margin-top: 25px;
  } */

  .navbar-nav::after {
    content: none;
  }

}

@media (min-width: 992px) {
  .container {
    max-width: 1130px;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1130px;
  }
}


.feature svg, .feature svg path{
  fill: var(--WhiteVM);
}
.lightModeOn .feature svg, .lightModeOn .feature svg path{
  fill: var(--BlackVM);
}

.test-card{
  background: var(--BlackVM);
  border-radius: 20px;
  padding: 30px 20px;
  display: flex;
  gap: 15px;
  height: 100%;
}
.detail{
  flex: 1;
}
.test-card .detail h4{
  color: var(--PrimaryVM);
    line-height: 50px;
}

.lightModeOn .test-card{
  background: var(--LightVM);
  border: 1px solid var(--PrimaryVM);
}

.lightModeOn .test-card .icon svg rect{
  fill-opacity: 1;
}

.bg3 {
  background-image: url(../public/assets/bg3.png);
  background-color: var(--LightBlackVM);
  background-position: center 80px;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 60px 0 80px 0;
  color: var(--WhiteVM);
}

.lightModeOn .bg3{
  background-image: url(../public/assets/bg3-vm.png);
  color: var(--BlackVM);
  background-color: var(--LightVM);
}
.solid{
  height: 58px;
}

.apps .tab-content {
  min-height: 120px;
}

.apps .nav-pills .nav-link {
  padding: 20px 30px;
  font-size: 16px;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  background: transparent;
  text-align: start;
  color: var(--WhiteVM);
  border: 1px solid transparent;
}
.lightModeOn .apps .nav-pills .nav-link {
  color: var(--BlackVM);
}
.apps .nav-pills .nav-link:hover {
  color:var(--PrimaryVM);
  border: 1px solid transparent;
}
.apps .nav-pills .nav-link svg{
  opacity: 0;
  transition: all .2s ease-in-out;
}
.apps .nav-pills .nav-link:hover svg{
  opacity: 1;
  transition: all .2s ease-in-out;
}
.apps .nav-pills .nav-link.active {
  color: var(--PrimaryVM);
  border: 1px solid var(--PrimaryVM);
  transition: all .2s ease-in-out;
}

.apps .nav-pills .nav-link.active svg{
  opacity: 1;
  transition: all .2s ease-in-out;
}
.lightModeOn .apps .nav-pills .nav-link svg path{
  fill: var(--BlackVM);
}

.mx-350{
  max-width: 350px;
  margin: 0 auto;
}
.mx-420{
  max-width: 420px;
  margin: 0 auto;
}

.eco-card{
  background: var(--BlackVM);
  padding: 60px 30px 40px;
  border-radius: 30px;
}

.eco-pills .nav.nav-tabs, .ecosystem .nav.nav-tabs{
    border-bottom: 0;
}

.eco-pills .tab-content, .ecosystem  .tab-content {
  min-height: 120px;
}

.eco-pills .nav-link, .ecosystem .nav-link {
  padding: 20px 30px 20px 0px;
  font-size: 16px;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  background: transparent;
  text-align: start;
  color: var(--WhiteVM);
  border: none;
  position: relative;
  white-space: nowrap;
}
.eco-pills .app-card, .apps-bg .app-card{
transform-style: preserve-3d;
  transition: transform .4s ease-in-out, box-shadow .4s ease-in-out, opacity .4s ease-in-out;
}

.eco-pills .app-card:hover, .apps-bg .app-card:hover {
    transform: translate3d(0, -16px, 0);
    box-shadow: 10px 64px 100px rgba(43, 64, 117, .15);
    opacity: .8;
}

.eco-pills .nav-link span{
  color: var(--PrimaryVM);
  position: absolute;
  bottom: 10px;
  right: 30px;
  font-size: 10px;
}

.eco-pills .nav-link:hover,.ecosystem .nav-link:hover {
  color:var(--PrimaryVM);
}
.eco-pills .nav-link svg{
  opacity: 0;
  transition: all .2s ease-in-out;
}
.eco-pills .nav-link:hover svg{
  opacity: 1;
  transition: all .2s ease-in-out;
}
.eco-pills .nav-link.active,.ecosystem .nav-link.active {
  color: var(--PrimaryVM);
  background-color: transparent;
  transition: all .2s ease-in-out;
}

.eco-pills .nav-link.active svg{
  opacity: 1;
  transition: all .2s ease-in-out;
}
.lightModeOn .eco-pills .nav-link svg path{
  fill: var(--BlackVM);
}

.lightModeOn .eco-card{
  color: var(--WhiteVM);
  background: var(--BlueVM);
}
.lightModeOn .ecosystem .nav-link{
  color: var(--BlackVM);
}
.lightModeOn .ecosystem .nav-link.active, .lightModeOn .ecosystem .nav-link:hover{
  color: var(--PrimaryVM);
}

.app-card{
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  padding: 10px;
  border-radius: 30px;
  background-color: var(--DarkVM);
}
.app-card p{
  font-size: 16px;
}
.lightModeOn .app-card{
  background: var(--LightVM);
  color: var(--BlackVM);
}

.app-card a{
  font-size: 15px;
  line-height: 29px;
  font-weight: 200;
  color: var(--PrimaryVM);
}

.lightModeOn .app-card svg path{
  fill: var(--BlackVM);
}

.solo-card{
  padding: 50px 30px;
  background: var(--BlackVM);
  color: white;
  border-radius: 30px;
  border: 1px solid transparent;
  position: relative;
}

.lightModeOn .solo-card{
  background: var(--LightVM);
  border: 1px solid var(--PrimaryVM);
  color: var(--BlackVM);
}
.solo-card p b{
  color: var(--PrimaryVM);
}

.nodesale .solo-card h4{
  font-size: 28px;
  color: var(--PrimaryVM);
}


.solo-card h3{
font-size: 40px;
}

.solo-card .accordion{
  background: transparent;
  border-radius: 0;
  border: none;
  border-color: transparent;
}

.solo-card .accordion-button{
  background: transparent;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  font-family: 'TT-Common-M';
  font-size: 24px;
  line-height: 1px;
}
.solo-card.faq-card .accordion-button{
  font-size: 19px;
  line-height: normal
}
.lightModeOn .solo-card .accordion-button{
  color: black;
}
.solo-card .accordion-button:not(.collapsed){
  box-shadow: none;
}
.accordion-button:not(.collapsed)::after,.accordion-button::after {
  content: none;
}

.solo-card .accordion-button svg{
  transform: rotate(90deg);
}
.solo-card .accordion-button.collapsed svg{
  transform: rotate(0deg) !important;
}

.solo-card .accordion-item {
  margin-bottom: 10px;
  border: 1px solid rgba(255, 255, 255, .5);
  border-radius: 10px;
  background: transparent;
}

.solo-card.faq-card{
  background: transparent;
  border:  none !important;
  padding: 0 !important;
}

.solo-card.faq-card .accordion-item{
  background: black;
  border: 1px solid transparent;
  padding: 15px;
}

.accordion-body{
  color: var(--WhiteVM);
}
.lightModeOn .accordion-body{
  color: var(--BlackVM);
}

.lightModeOn .solo-card .accordion-item {
  border: 1px solid black;
}
.lightModeOn .solo-card .accordion-button svg path{
  fill: var(--BlackVM);
}

.lightModeOn .solo-card.faq-card .accordion-item {
  border: 1px solid var(--PrimaryVM);
  background: white;
}
.lightModeOn .solo-card.faq-card .accordion-button svg path{
  fill: var(--BlackVM);
}

.build p{
  font-weight: 400;
}

.badge-b{
  background: var(--PrimaryVM);
  padding: 8px 25px;
  font-size: 18px;
  color: white;
  border-radius: 30px;
  display: inline-block;
}

.text-white {
  color: white;
}

.secure{
  background: var(--BlackVM);
  padding: 40px 0;
}
.lightModeOn .secure{
  background: var(--BlueVM);
  color: white;
}
.secure h1{
  font-size: 62px;
  color: var(--PrimaryVM);
  letter-spacing: -2px;
  font-family: "Inter", sans-serif;
}
.secure p{
}

.secure .border-end{
  border-color: rgba(255, 255, 255, .5) !important;
}

.launch {
  margin-top: 6px;
  background: var(--BlackVM);
  padding: 40px 0;
  color: white;
}

.lightModeOn .launch {
  background: var(--BlueVM);
}

.vm-text{
  font-size: 24px;
}
.vm-text span{
  font-weight: 200;
}


.left-aside{
  background: #EA973D29;
background-blend-mode: color;
background: #EA973D33;
padding: 40px 30px;
border-radius: 20px;
height: 100%;
}

.lightModeOn .left-aside{
  background: #EA973D29;
background-blend-mode: color;
background: #EA973D33;
color: var(--BlackVM);

}

.text-md{
  font-size: 22px;
}

.left-aside h3{
  font-size: 36px;
  line-height: 44px;
  color: var(--WhiteVM);
}

.lightModeOn .left-aside h3{
  color: var(--BlackVM);
}

.right-aside{
  background: #F19938;
  padding: 40px 30px;
  border-radius: 20px;
  height: 100%;
}

.right-aside h3{
  font-size: 36px;
  line-height: 1;
  color: var(--BlackVM);
}

.right-aside .primary-btn{
  background: var(--BlackVM);
  border: 1px solid var(--BlackVM)
}

.right-aside .primary-btn:hover{
  color: var(--BlackVM);
  background: transparent;
}

.left-aside p,.right-aside p{
  font-weight: 400;
}

.news-btn{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--PrimaryVM);
  padding: 20px;
  border-radius: 20px;
  transition: all .2s ease-in-out;
}

.news-btn:hover{
  background: var(--PrimaryVM);
  transition: all .2s ease-in-out;
}

.lightModeOn .news-btn{
  color: var(--BlackVM);
  background: var(--LightVM);
}
.lightModeOn .news-btn svg path{
  fill: var(--BlackVM);
  transition: all .2s ease-in-out;
}
.lightModeOn .news-btn:hover svg path{
  fill: var(--WhiteVM);
  transition: all .2s ease-in-out;
}
.lightModeOn .news-btn:hover{
  color: var(--WhiteVM);
  background: var(--PrimaryVM);
  transition: all .2s ease-in-out;
}
.news .row{
  margin: 0 -5px;
}
.news .row .col{
  padding: 0 5px;
}
.swiper-img{
    transform-style: preserve-3d;
    transition: transform .4s ease-in-out, box-shadow .4s ease-in-out, opacity .4s ease-in-out;
    display: inline-block;
}

.swiper-img:hover{
    transform: translate3d(0, -16px, 0);
    box-shadow: 10px 64px 100px rgba(43, 64, 117, .15);
    opacity: .8;
}

.navigation-buttons{
  display: flex;
  align-items: center;
  gap: 30px;

}
.swiper-button-prev-custom,.swiper-button-next-custom{
  cursor: pointer;
  background: transparent;
  border: none;
}
.swiper-button-prev-custom svg{
  transform: rotate(180deg);
}

.lightModeOn .swiper-button-prev-custom svg path,
.lightModeOn .swiper-button-next-custom svg path{
  fill: var(--BlackVM);
}

/* Swiper  */

.swiper-box p{
  padding: 20px 0 0 0;
  font-family: "Inter", sans-serif;
}


footer {
}

.f-card {
  padding: 50px 0 10px 0;
}

footer h5 {
  font-size: 18px;
  color: #EA973D;
  font-weight: 400;
  margin-bottom: 20px;
  font-family: 'TT-Common-R';
}

footer ul li a {
  font-size: 14px;
  color: #fff;
  font-weight: 300;
  margin-bottom: 10px;
  display: inline-block;
  transition: all .3s linear;
}

.lightModeOn footer a, .lightModeOn .sub-footer{
  color: var(--BlackVM);
}

footer ul li:last-child a {
  margin-bottom: 0;
}


footer ul li a:hover {
  color: #EA973D;
  transition: all .3s linear;
}

.f-link {
  display: inline-block;
  transition: all .3s ease-in-out;
}

.f-link:hover {
  transform: translate(0px, -5px);
  transition: all .3s ease-in-out;
}
.faq-link{
  color: var(--PrimaryVM);
  text-decoration: underline;
}
.faq-link:hover{
  color: var(--PrimaryVM);
  text-decoration: underline;
}

.sub-footer{
  font-size: 14px;
  color: #fff;
  font-weight: 300;
  text-align: center;
  padding: 15px 0px;
  padding-bottom: 30px;
}



.input-ic {
  position: relative;
}

form input[type = 'email'] {
  border: 1px solid rgb(255 255 255 / 50%);
  background: transparent;
  padding: 12px 20px;
  color: white;
  border-radius: 12px;
  width: 100%;
  padding-right: 125px;
}

.lightModeOn form input[type = 'email']{
  border: 1px solid var(--BlackVM);
  color: black;
}

.input-ic input::placeholder {
  color: white;
}
.lightModeOn .input-ic input::placeholder {
  color: black;
}

.input-ic button {
  position: absolute;
  right: 5px;
    top: 5px;
    transform: translate(0px, 0px);
    transition: all .3s ease-in-out;
    background: var(--PrimaryVM);
    padding: 8px 30px;
    border-radius: 12px;
    border: none;
}
input:focus, input:focus-visible{
  border: 1px solid var(--PrimaryVM);
}
.lightModeOn .input-ic button svg path{
      fill: var(--BlackVM);
}
.input-ic button svg{
  transform: translate(0px, 0px);
  transition: all .3s ease-in-out;
}
.input-ic button:hover svg{
  transform: translate(5px, 0px);
  transition: all .3s ease-in-out;
}

input[type="checkbox"] {
  margin-right: 10px;
  transform: translateY(4px);
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  border-radius: 3px;
  appearance: none;
}
.lightModeOn input[type="checkbox"] {
  border: 2px solid #000;

}
.custom-input::before {
  display: block;
  content: "";
  width: 18px;
  height: 18px;
  /* transition: 120ms transform ease-in-out; */
  /* box-shadow: inset 14px 14px #fff; */
}
input[type="checkbox"].custom-input:checked {
  border: none;
}
input[type="checkbox"].custom-input:checked::before {
  background: url(../public/assets/check.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.lightModeOn input[type="checkbox"].custom-input:checked::before {
  background: url(../public/assets/check-vm.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mega-top{
  position: absolute;
  top: 40px;
  left: -35%;
  padding-top: 6px;
}

.mega-menu{
  width: 800px;
  /* max-width: 100%; */
  padding: 30px 15px;
  border: 1px solid var(--PrimaryVM);
  border-radius: 20px;
  background: var(--BlackVM);
  opacity: 0;
  visibility: hidden;
  z-index: -111;
  display: none;
}
.lightModeOn .mega-menu{
  background:var(--LightVM);
  color: black;
}
.nav-item.indi{
  cursor: pointer;
}
.mega-menu ul li a{
  display: flex;
  gap: 12px;
  text-align: start;
  padding: 12px;
  border-radius: 16px;
  transition: all .2s ease-in-out;
}

.mega-menu ul li a:hover{
  background: #2B1D11;
  transition: all .2s ease-in-out;
}

.lightModeOn .mega-menu ul li a:hover{
  background: #fff;
  transition: all .2s ease-in-out;
}

.mega-menu ul li a h4{
  flex: 1;
}
.mega-menu ul li a h4{
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.mega-menu ul li p{
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
}

.indi:hover .mega-menu{
  visibility: visible;
  opacity: 1;
  z-index: 1111;
  display: block;
  transition: all .3s ease-in-out;
}

.social-ic{
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background: #3E2E22;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0px, 0px);
  transition: all .3s ease-in-out;
}

.social-ic:hover{
  transform: translate(0px, -5px);
  transition: all .3s ease-in-out;
}

.lightModeOn .social-ic{
  background: var(--PrimaryVM);
}


.lightModeOn .indi .menu-link svg path{
  fill: var(--BlackVM);
}



.feature-card{
  background-color: var(--DarkVM);
  border-radius: 30px;
  height: 100%;
  display: block;
  border: 1px solid var(--DarkVM);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top-content{
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.bottom-content{
  padding: 20px;
}
.feature-card img{
  max-width: 60px;
}

.feature-badge{
  background: #AB6E2C;
  border-radius: 20px;
  font-size: 11px;
  padding: 8px 12px 6px 12px;
  color: var(--WhiteVM);
}

.lightModeOn .feature-card{
  background: var(--WhiteVM);
  border-color: var(--PrimaryVM);
}
.feature-link{
  padding: 0 20px 20px 20px;
}
.feature-link a{
  font-size: 14px;
  padding: 8px 20px;

}

.lightModeOn .top-content{
  border-color: var(--PrimaryVM);
}

.categories{
  background: var(--BlackVM);
  border-radius: 30px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.apps-bg{
  background: var(--BlackVM);
  border-radius: 30px;
  padding: 30px;
}
.apps-bg .allApps{
  min-height: 500px;
}
.categories button, .modal-body .nav button{
  color: white;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 16px;
  background: transparent;
  text-align: left;
  transition: all .2s ease-in-out;
}
.lightModeOn .modal-body .nav button{
  color: var(--BlackVM);
}
.categories button.active,.categories button:hover,
.modal-body .nav button.active, .modal-body .nav button:hover{
  border-color: var(--PrimaryVM);
  color: var(--PrimaryVM);
  transition: all .2s ease-in-out;
  background: transparent;
}
.modal-body .nav button{
  width: 100%;
  color: var(--PrimaryVM);
  background: transparent;
}

.input-with-ic{
  position: relative;
}
.input-with-ic input{
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 10px 20px 10px 50px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 25px;
  color: rgba(255, 255, 255, 0.7);
}
.input-with-ic a{
  position: absolute;
  left: 10px;
  top: 10px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.lightModeOn .input-with-ic input{
  border-color: var(--PrimaryVM);
  color: black;
}
.lightModeOn .input-with-ic a svg path{
  fill: var(--PrimaryVM);
}
.lightModeOn .categories, .lightModeOn .apps-bg{
  background: var(--BlueVM);
  color: white !important;
}

.apps-bg .app-card p{
  font-size: 14px;
  text-align: center;
  margin: 0;
}

.steps-card{
  background: var(--BlackVM);
  padding: 25px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  height: 100%;
  color: white !important;
}
.steps-card h6{
  font-size: 18px;
  line-height: 24px;
}
.steps-card h6 a{
  color: var(--PrimaryVM);
}
.step-badge{
  background: #AB6E2C;
  border-radius: 20px;
  font-size: 10px;
  padding: 4px 10px 4px 10px;
  color: var(--WhiteVM);
  display: inline-block !important;
}
.step-img{
  text-align: center;
  width: 100%;
  height: 120px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.step-img img{
  object-fit: contain;
  height: 100%;
}

.lightModeOn .steps-card{
  background: var(--BlueVM);
}


/* NodeSale v2  */
/* .nodesale.v2 .bg-grad{
  padding-top: 0px;
} */
.nodesale.v2 .banner{
  /* background: url(../public/assets/nodesalev2/shadow.png); */
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto;
  padding-top: 0px;
}


/* .nodesale.v2 .banner h1{
  font-size: 48px;
    line-height: 54px;
}
.nodesale.v2 .banner p{
  font-weight: normal;

} */
.sale-card{
  background: white;
  border-radius: 20px;
  padding: 20px 30px;
  position: relative;
}
.sale-card h2{
  text-transform: uppercase;
  font-size: 34px;
  color: var(--BlackVM);
  line-height: 40px;
  font-weight: bold;
}

.countdown-timer{
  display: flex;
  gap: 20px;
  color: #5C5D5F;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.time-unit.sec{
  color: var(--PrimaryVM);
}
.label{
  font-size: 16px;
  font-weight: normal;
  text-align: center;
}
.progress-bar{
  height: 6px;
  border-radius: 0;
}
.progress-bar .progress{
  background: var(--BlackVM);
  border-radius: 0;
  height: 6px;
}
.banner-sale.sale-card{
  margin-top: 70px;
  padding-bottom: 5px ;
  padding-left: 20px;
  padding-right: 20px;
}
.banner-sale.sale-card .countdown-timer {
  gap: 15px;
  font-size: 28px;
  justify-content: center;
}
.banner-sale.sale-card .label {
  font-size: 15px;
}
.banner-sale.sale-card .progress-bar{
  background: var(--BlackVM);
}
.banner-sale.sale-card .progress-bar .progress{
  background: var(--PrimaryVM);
}

.banner-badge{
  background: transparent;
  border: 2px solid var(--PrimaryVM);
  backdrop-filter: blur(10px);
  padding: 12px 20px;
  color: white;
  font-size: 16px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 140px;
  top: -35px;
  border-radius: 12px;
}


.grow{
  position: absolute;
  right: -40px;
  top: -40px;
}
.grow img{
  width: 100%;
  max-width: 80px;
  border-radius: 100px;
  box-shadow: 0px 30.08px 50.58px -6.84px #00000070;

}
.cat-bg{
  background: var(--DarkVM);
  padding: 40px 40px 0px 40px;
  border-radius: 15px;
}
.cat-card{
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.1) 100%);
  position: relative;
  border-radius: 15px;
  padding: 1px;
}
.bg-cat{
  padding: 35px 20px 0px 20px;
  border-radius: 15px;
  background:  var(--DarkVM);
}
.cat-name{
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -20px;
  background: var(--PrimaryVM);
  border-radius: 14px;
  font-size: 14px;
  color: var(--BlackVM);
  padding: 5px 15px;
  display: inline-block;
  max-width: fit-content;

}
@media (max-width: 1023px) {
  .solo-card h3 {
    font-size: 30px;
}
  .solo-card .accordion-button{
    line-height: 22px;
    font-size: 20px;
  }
  h2 {
    font-size: 38px;
    line-height: 42px;
}
.secure h1 {
  font-size: 50px;
}
.left-aside h3, .right-aside h3 {
  font-size: 28px;
  line-height: 36px;
}
.banner h1 {
  font-size: 42px;
  line-height: 50px;
}
}

@media (max-width: 1220px) {
  .bg3 img{
    width: 100%;
  }
}

.back-btn{
  display: none;
}


.nodeBg{
  background: url(../public/assets/node-bg.png);

}

.nodeBg h2{
  background: linear-gradient(180deg, #FFB96D 0%, #FF8600 100%) ;
-webkit-background-clip: text ;
  -webkit-text-fill-color: transparent ;
  font-weight: 800 ;

}

.nodesale.v2 .nodeBg{
  background: url(../public/assets/nodesalev2/bg-node.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.lightModeOn .nodesale.v2 .nodeBg{
  background: url(../public/assets/nodesalev2/node-bg-vm.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.nodesale.v2 .nodeBg h2{
  background: none ;
-webkit-background-clip: var(--WhiteVM) ;
  -webkit-text-fill-color: white ;
  font-weight: 800 ;

}
.earn-bg{
  background-image: url(../public/assets/nodesalev2/earn-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.lightModeOn .earn-bg{
  background-image: url(../public/assets/nodesalev2/earn-bg-vm.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.lightModeOn .nodesale.v2 .nodeBg h2{
-webkit-background-clip: var(--BlackVM) ;
  -webkit-text-fill-color: var(--BlackVM) ;

}
.nodesale.v2 .primary-btn{
  color: var(--BlackVM);
  height: 54px;
}
.nodesale.v2 .primary-btn:hover{
  color: var(--PrimaryVM);
  height: 54px;
}
.lightModeOn .nodesale.v2 .primary-btn-outline{
  color: var(--PrimaryVM);
}
.nodesale.v2 .primary-btn-outline:hover{
  color: var(--PrimaryVM);
}

.soonbadge{
  background: #F1993833;
  border-radius: 50px;
  color: var(--PrimaryVM);
  padding: 5px 10px;
  font-size: 12px;
}
.lightModeOn .soonbadge{
  color: var(--BlackVM);
}

.com-soon{
  background: var(--BlackVM);
  padding: 10px 30px;
  color: white;
  font-size: 16px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 160px;
  bottom: -20px;
  border-radius: 30px;
}
.nodesale.v2 .solo-card{
  border-radius: 20px;
}
.lightModeOn .nodesale.v2 .solo-card{
  border: 2px solid #171B27;
}
.landscape .container{
  border: 4px solid black;
  border-top: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 30px 50px 40px 50px;
}

.landscape h4{
  color: var(--PrimaryVM);
  font-size: 30px;
}

.landscape p{
  font-weight: 400;
}
.landscape .primary-btn-outline{
  font-size: 14px;
}
.prog-bar{
  height: 6px;
  width: 100%;
  background: white;
  margin: 10px 0;
}
.lightModeOn .prog-bar{
  background: var(--BlackVM);
}
.pro-lable{
  font-size: 14px;
  color: white;
}
.prog-bar .pro-progress{
  background: var(--PrimaryVM);
  height: 100%;
} 
.nodesale.v2 .primary-btn, .nodesale.v2 .primary-btn-outline{
  text-transform: uppercase;
  font-size: 14px;
}

.solo-card .hide{
  position: absolute;
  height: 100%;
  border-radius: 20px;
  backdrop-filter: blur(4px);
    width: 100%;
    left: 0;
    top: 0;
    z-index: 20px;
}

.not-visible{
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
.not-visible.is-visible{
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

@media (max-width: 767px) {
  .not-visible{
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }
  .top-banner {
    font-size: 10px;
    padding: 8px 0;
    margin-bottom: 4px;
  } 
  .home-sm .header-icons{
    position: fixed;
    bottom: 80px;
    padding: 20px;
  }
  /* .nodesale .banner h1 {
    font-size: 46px;
    line-height: 54px;
} */
.b1-img, .b2-img {
  max-width: 100%;
}

  .mega-menu{
    width: 100%;
    left: 0;
    right: 0;
    border: none;
    border-radius: 0;
    top: -20px;
  }
.menu-img{
  display: none;
}
  h2 {
    font-size: 38px;
    line-height: 44px;
  }

  h5 {
    font-size: 18px;
  }

  .bg-grad {
    padding: 120px 0px 0px 0px;
  }

  .banner {
    padding-top: 0px;
    padding-bottom: 25px;
    background: none;
  }

  .banner h1 {
    font-size: 40px;
    line-height: 46px;
  }

  .banner p {
    font-size: 14px;
    line-height: 26px;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }
  .banner-card{
    padding: 20px;
  }
.feature-card{
  min-height: 375px;
}
.feature-card     p {
  font-size: 16px;
  line-height: 26px;
}
  .feature .op-dark,.feature .op-light{
    height: 100% !important;
    width: 100% !important;
  }
  .test-card{
    border-radius: 10px;
    height: 130px;
  }

 .sp-img img{
  height: 40px;
 }
 .bg3 {
  background-image: none;
  padding: 50px 0 50px 0;
  color: var(--WhiteVM);
}
 .lightModeOn .bg3{
  background-image: none;
}
.nodesale.v2 .primary-btn{
  height: 46px;
}
 .bg3 .op-dark, .bg3 .op-light{
      height: auto;
      width: 100%;
      max-width: 150px;
 }
 .row.no-wrap{
  margin: 0 -5px;
 }
 .row.no-wrap .col-5{
  padding: 0 5px;
 }
.mega-top {
  position: absolute;
  top: -20px;
  padding-top: 0px;
  z-index: 1111;
  width: 100%;
  left: 0;
  right: 0;
  border: none;
  border-radius: 0;
}
.test-card .detail h4 {
  font-size: 32px;
  line-height: 34px;
}
.test-card .detail p {
  font-size: 18px;
  line-height: 24px;
}
.nodesale.v2 .bg-grad {
  padding: 100px 0px 0px 0px;
}

  .primary-btn,
  .secondary-btn, .learn-btn-1 {
    padding: 14px 25px;
    font-size: 16px;
    display: flex;
    width: 270px;
    max-width: 300px;
    margin: 0 auto;
  }
  .learn-btn-1{
    padding: 12px 15px;
    font-size: 12px;
  }
  .soonbadge {
    font-size: 8px;
  }
  .primary-btn.d-lg-none{
    width: auto;
    padding: 4px 25px;
    border-radius: 8px;
  }
  .f-img {
    height: 45px;
  }
.responsive-table{
  overflow-x: auto;
  overflow-y: hidden;
}
  .footer-logo img{
    height: 34px;
  }

  .apps .nav-pills .nav-link {
    padding: 10px 20px;
  font-size: 16px;
  border-radius: 15px;
  }
  .app-card a {
    font-size: 13px;
  }
  h4 {
    font-size: 24px;
    line-height: 26px;
}
.eco-card, .solo-card {
  padding: 30px 20px 30px;
  border-radius: 16px;
}
.solo-card .accordion-button{
  font-size: 22px;
    line-height: 22px;
}
.badge-b {
  padding: 5px 15px;
  font-size: 14px;
}
.secure h1 {
  font-size: 52px;
  border-bottom: 6px solid var(--DarkVM);
  margin: 0 -12px;
  padding-bottom: 20px;
}
.lightModeOn .secure h1{
  border-bottom: 6px solid var(--WhiteVM);

}
.secure {
  padding: 20px 0;
}
.vm-text{
  display: none;
}
.left-aside , .right-aside{
  padding: 25px 20px;
}

.swiper-box{
  display: flex;
  align-items: center;
  gap: 15px;
}

.swiper-box p{
  padding-top: 0;
  flex: 1;
  font-size: 14px;
  margin-bottom: 0;
}

.swiper-img{
  height: auto;
  width: 100px;
}

.swiper-img img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

footer ul li a {
  font-size: 12px;
  margin-bottom: 5px;
}

.sub-footer{
  font-size: 12px;
  padding-top: 0;
  padding-bottom: 12px;
}
.footer-logo img{
  height: 38px;
}
.mega-menu ul li a p{
  margin-bottom: 20px;
}
.mega-menu ul li a{
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.lightModeOn .mega-menu ul li a{
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}
.mega-menu ul li a.last{
  border-bottom: 0px solid rgba(255, 255, 255, 0.6);
}

.back-btn{
  display: inline-flex;
  align-items: center;
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 700;
}
.lightModeOn .back-btn svg path{
  fill: var(--BlackVM);
}
.mega-menu ul li a h4 {
  font-size: 18px;
}
.indi:hover .mega-menu{
  visibility: hidden;
  opacity: 0;
  z-index: -11;
  display: none;
  transition: all .3s ease-in-out;
}

.mega-toggle .indi .mega-menu{
  visibility: visible;
  opacity: 1;
  z-index: 1111;
  display: block;
  transition: all .3s ease-in-out;
}
.solo-card.faq-card .accordion-item {
  padding: 0px;
}
.solo-card.faq-card .accordion-button {
  font-size: 16px;
}
.solo-card .accordion-button svg {
  width: 24px;
}
.flex-1{
  flex: 1;
}
.solo-card .accordion-button svg {
  transform: rotate(0deg);
}
.solo-card .accordion-button:not(.collapsed) svg {
  transform: rotate(90deg);
}
.app-card {
  height: 135px;
  gap: 15px;
  border-radius: 10px;
}
.app-card p {
  font-size: 13px;
}
.app-card img{
  max-width: 60px;
}
.apps-bg {
  padding: 12px;
  border-radius: 15px;
}
.allApps .row{
  margin: 0 -5px;
}
.allApps .row .col-4{
  padding: 0 5px;
}
.apps-bg .allApps {
  min-height: 430px;
}
.allApps .app-card img{
  max-width: 40px !important;
}
.allApps .app-card {
  height: auto;
  padding-top: 12px;
}
.allApps .app-card p{
  margin: 0;
  font-size: 12px;
}
.modal-content{
  border-radius: 0;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: var(--DarkVM);
}
.lightModeOn .modal-content{
  background: var(--LightVM);
}
.modal-dialog-centered.modal-dialog-scrollable{
  align-items: end;
  max-height: 200px;
  overflow-y: auto;
}

/* .nodesale.v2 .banner {
  padding-top: 80px;
} */
/* .nodesale.v2 .banner h1 {
  font-size: 44px;
  line-height: 50px;
} */
.sale-card {
  padding: 20px;
}
.sale-card h2{
  font-size: 26px;
  font-weight: 30px;
}
.grow {
  right: -5px;
  top: -25px;
}
.grow img {
  max-width: 80px;
}
.nodesale.v2 .nodeBg, .lightModeOn .nodesale.v2 .nodeBg {
  background-position: top;
  background-size: contain;
}
.landscape .container{
  border: 4px solid black;
  border-top: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px;
}
.landscape h4{
  line-height: 34px;
}
}
